import * as React from "react";
import { render } from "react-dom";

import { Headline } from "./Components/Headline";

render(
  <Headline>
  </Headline>
  , document.getElementById("header")
);
