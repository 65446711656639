import * as React from "react";

interface State {
}

export class Headline extends React.Component<any, State> {
  public state: State;

  constructor(props: any) {
    super(props);
  }

  public render() {
    return <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="left">
          <a href="/">
            <svg width="48px" height="48px" viewBox="0 0 192 192" version="1.1" xmlns="http://www.w3.org/2000/svg" style={{ fill:"evenodd", clip:"evenodd", strokeLinecap:"round", strokeLinejoin:"round", strokeMiterlimit:1.5}}>
              <g id="Ebene1">
                  <g transform="matrix(1.01106,0,0,1.00074,-1.16338,-0.0265647)">
                      <path d="M18.818,156.046C18.818,156.046 36.722,116.69 58.259,88.334C83.345,55.306 105.195,35.724 105.195,35.724" style={{ fill:"none", stroke:"black", strokeWidth:"5.47px" }}/>
                  </g>
                  <path d="M69.201,90.825L68.782,128.484C68.782,128.484 68.075,152.225 79.499,151.218C89.317,150.353 108.395,120.161 108.395,120.161L109.757,134.178C109.757,134.178 110.077,144.927 124.355,142.386C152.657,137.348 164.621,138.858 164.621,138.858L174.774,138.983" style={{ fill:"none", stroke:"black", strokeWidth: "5.5px" }}/>
              </g>
            </svg>
          </a>
        </div>
        <div className="center" style={{ flex: 1 }}></div>
        <div className="right" style={{ verticalAlign: "middle" }}>
          {/*
          <div style={{ marginLeft: "4px", marginRight: "4px", display: "inline-block" }}>
            <button className="knopf flat">
              Login
            </button>
          </div>
          <div style={{ marginLeft: "4px", marginRight: "4px", display: "inline-block" }}>
            <button className="knopf pale">
              Register
            </button>
          </div>
          */}
        </div>
      </div>;
  }
}
